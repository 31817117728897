
import Vue from "vue";
import ICostCenter from "@/lib/interfaces/cost-center";
import CostCenterList from "@/components/subviews/cost-center/cost-center-list.vue";
import CostCenterMaintenance from "@/components/subviews/cost-center/cost-center-maintenance.vue";

export default Vue.extend({
  name: "CostCenter",

  components: { CostCenterList, CostCenterMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      costCenter: {} as ICostCenter,
    };
  },
});
