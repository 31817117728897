
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import ICostCenter from "@/lib/interfaces/cost-center";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";

export default Vue.extend({
  name: "CostCenterMaintenance",

  components: {
    ABtn,
    ASwitch,
    ACard,
    ATextField,
    vAlertMessage,
    ABtnDialogConfirm,
  },

  props: {
    costCenter: {
      type: Object as PropType<ICostCenter>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataCosts: {
        id: "",
        descricao: "",
        desativado: "0",
      } as ICostCenter,
    };
  },

  watch: {
    costCenter: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataCosts = {
          ...this.costCenter,
        };
      },
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    cTextSave(): string {
      return this.dataCosts.id ? "Alterar" : "Salvar";
    },
  },
  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.write");
        const tblCusto = new Table("centro_custo");

        const obj = {
          ...this.dataCosts,
          id: this.dataCosts.id || "default",
        };
        tblCusto.setColsFromObject(obj);
        tblCusto.addRowsFromObject(obj);

        rq.addTable(tblCusto);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um custo com esse nome";
          return;
        }

        this.ret_msg = `Valor ${
          this.dataCosts.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataCosts.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataCosts = {
        id: "",
        descricao: "",
        desativado: "0",
      };

      this.form.resetValidation();
    },

    async openDetails(id: string) {
      this.$router.push({ name: "cost-center", params: { id } });
    },
  },
});
